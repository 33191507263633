<template>
  <div class="col-md-12">
    <div
      class="d-flex flex-wrap align-items-end px-3 py-3 hover-shadow"
      draggable="true"
      v-on:dragstart="startDrag"
      v-on:drop="onDrop"
      v-on:dragover.prevent="onDragOver"
      v-on:dragenter.prevent
      v-on:dragleave.prevent="onDragLeave"
      ref="dropzone"
    >
      <div class="col-auto pl-0">
        <div v-html="getHtml" style="width: 200px;"></div>
      </div>
      <!-- Order No -->
      <div class="col-auto">
        <label class="font-12">{{ $t("orderNumber") }}</label>
        <input
          type="text"
          class="form-control form-control-sm"
          style="max-width: 60px"
          min="1"
          v-bind:max="pictures.length"
          v-bind:value="picture.orderNumber"
          v-on:input="setOrderNumber"
        />
      </div>
      <!-- Order No -->

      <div class="col-12 col-lg pl-0">
        <label class="font-12">{{ $t("startingDate") }}</label>
        <WeDatePicker
          v-bind:id="'slider-start-date-' + pictureIndex"
          v-bind:label="$t('startingDateHour')"
          v-bind:format="'DD.MM.YYYY HH:mm'"
          v-bind:output-format="'YYYY-MM-DD HH:mm'"
          v-bind:locale="'tr'"
          v-bind:no-label="true"
          v-bind:no-button-now="true"
          input-size="xs"
          v-model="picture.startDate"
        />
      </div>

      <div class="col-12 col-lg pl-0">
        <label class="font-12">{{ $t("endingDate") }}</label>
        <WeDatePicker
          v-bind:id="'slider-due-date-' + pictureIndex"
          v-bind:label="$t('endingDateHour')"
          v-bind:format="'DD.MM.YYYY HH:mm'"
          v-bind:output-format="'YYYY-MM-DD HH:mm'"
          v-bind:locale="'tr'"
          v-bind:no-label="true"
          v-bind:no-button-now="true"
          input-size="xs"
          v-model="picture.dueDate"
        />
      </div>

      <div class="col-auto pl-0">
        <div class="row align-items-end">
          <div class="col">
            <label class="font-12 mb-2">{{ $t("status") }}</label>
            <div class="text-lg-center">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-bind:id="pictureIndex + '-picture-status'"
                  v-bind:disabled="getDisableStatus"
                  v-model="picture.active"
                />
                <label
                  class="custom-control-label"
                  v-bind:for="pictureIndex + '-picture-status'"
                ></label>
              </div>
            </div>
          </div>
          <div class="col p-0">
            <a class="btn btn-sm btn-outline-primary" v-on:click="modal = true"
              ><i class="fas fa-eye"></i
            ></a>
          </div>
          <div class="col">
            <a
              class="btn btn-sm btn-outline-success"
              v-on:click="contentModal = true"
              ><i class="fas fa-align-left"></i
            ></a>
          </div>
          <div class="col p-0">
            <a class="btn btn-sm btn-outline-danger" v-on:click="onRemove"
              ><i class="fas fa-trash"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <WeModal
      v-show="modal"
      v-bind:title="$t('imageUpload')"
      v-on:close="closeModal"
    >
      <div
        slot="body"
        style="max-height: 500px; overflow-y: auto; overflow-x: hidden"
        class="custom-scrollbar pr-3"
      >
        <div class="row">
          <div class="col-12 col-md">
            <WeImageUploader
              v-model="picture.desktop"
              v-bind:image-required="true"
              v-bind:title="$t('desktop')"
              v-bind:fixed-meta-alt="picture.title"
              v-bind:fixed-meta-title="picture.description"
              ref="desktopUploader"
              v-bind:hide-dropzone="hideDropzoneDesktop"
            />
          </div>

          <div
            class="col-12 col-md"
            v-show="
              picture.desktop &&
              picture.desktop.files &&
              picture.desktop.files.length
            "
          >
            <WeImageUploader
              v-model="picture.mobile"
              v-bind:image-required="true"
              v-bind:title="$t('mobile')"
              v-bind:fixed-meta-alt="picture.title"
              v-bind:fixed-meta-title="picture.description"
              ref="mobilUploader"
              v-bind:hide-dropzone="hideDropzoneMobile"
            />
          </div>

          <div class="col-md-12">
            <div class="row align-items-center">
              <!-- URL -->
              <div class="col-9 mb-2 col-lg mb-lg-0">
                <div class="d-block">
                  <small>{{ $t("link") }}</small>
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="picture.url"
                />
              </div>
              <!-- URL -->

              <!-- New Tab -->
              <div class="col mb-2 col-lg-auto mb-lg-0 text-right">
                <div class="d-block mb-2">
                  <small>{{ $t("newTab") }}</small>
                </div>
                <div class="text-lg-center">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      v-bind:id="pictureIndex + '-picture-new-tab'"
                      v-model="picture.newTab"
                    />
                    <label
                      class="custom-control-label"
                      v-bind:for="pictureIndex + '-picture-new-tab'"
                    ></label>
                  </div>
                </div>
              </div>
              <!-- New Tab -->
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <WeSubmitButton v-on:submit="closeModal" />
      </div>
    </WeModal>

    <WeModal
      v-show="contentModal"
      v-bind:title="'İçeriği Düzenle'"
      v-on:close="closeContentModal"
    >
      <div
        slot="body"
        style="max-height: 500px; overflow-y: auto; overflow-x: hidden"
        class="custom-scrollbar pr-3"
      >
        <div class="row">
          <div class="col-10">
            <label class="font-12">{{ $t("title") }}</label>
            <input
              type="text"
              class="form-control form-control-sm"
              v-bind:value="picture.title"
              v-on:input="setTitle"
            />
          </div>
          <div class="col-2 mt-2 text-center">
            <label class="font-12">Başlık Rengi</label>
            <verte
              picker="square"
              model="hex"
              v-bind:show-history="false"
              menu-position="right"
              v-model="picture.title_color"
            ></verte>
          </div>
          <div class="col-10 mt-2">
            <label class="font-12">{{ $t("description") }}</label>
            <textarea
              class="form-control"
              v-bind:value="picture.description"
              v-on:input="setDescription"
            >
            </textarea>
          </div>
          <div class="col-2 mt-4 text-center">
            <label class="font-12">Açıklama Rengi</label>
            <verte
              picker="square"
              model="hex"
              v-bind:show-history="false"
              menu-position="right"
              v-model="picture.desc_color"
            ></verte>
          </div>
          <div class="col-8 mt-2">
            <label class="font-12">Buton Yazısı</label>
            <input
              type="text"
              class="form-control form-control-sm"
              v-bind:value="picture.btn_text"
              v-on:input="setBtnText"
            />
          </div>
          <div class="col-2 mt-4 text-center">
            <label class="font-12">Buton Yazı Rengi</label>
            <verte
              picker="square"
              model="hex"
              v-bind:show-history="false"
              menu-position="right"
              v-model="picture.btn_text_color"
            ></verte>
          </div>
          <div class="col-2 mt-4 text-center">
            <label class="font-12">Buton Arkaplan Rengi</label>
            <verte
              picker="square"
              model="hex"
              v-bind:show-history="false"
              menu-position="right"
              v-model="picture.btn_bg_color"
            ></verte>
          </div>
          <div class="col-12 mt-2">
            <label class="font-12">İçerki Konumu Seç</label>
            <select
              id="content-position-list"
              class="form-control  form-control-sm"
              v-model="picture.content_position"
            >
              <option
                v-for="position in contentPositionList"
                v-bind:key="position.key"
                v-bind:value="position.key"
              >
                {{ position.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div slot="footer">
        <WeSubmitButton v-on:submit="closeContentModal" />
      </div>
    </WeModal>
  </div>
</template>
<script>
import Verte from "verte";
import "verte/dist/verte.css";

export default {
  name: "WePicture",
  data() {
    return {
      contentPositionList: [
        { key: "left", name: "Sol" },
        { key: "right", name: "Sağ" },
        { key: "center", name: "Orta" },
      ],
      modal: false,
      contentModal: false,
      desktop: {
        files: [],
        filesData: null,
      },
      mobile: {
        files: [],
        filesData: null,
      },
    };
  },
  components: {
    Verte,
  },
  props: {
    pictures: {
      type: Array,
      default: () => [],
    },
    pictureIndex: {
      default: 0,
    },
    activeCount: {
      default: 0,
    },
    activeLimit: {
      default: 10,
    },
  },
  methods: {
    dropzoneHighlight(unhighlight) {
      const dropzone = this.$refs.dropzone;

      if (!unhighlight) {
        dropzone.classList.add("highlight");
      } else {
        dropzone.classList.remove("highlight");
      }
    },
    uploadPictureTrigger() {
      this.fileInput.click();
    },
    selectFile() {
      const input = this.fileInput;

      if (input.files.length) {
        this.createFile(input.files[0]);
      }
    },
    createFile(file) {
      if (file.size <= parseInt(1024) * 1024) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.picture.imageData = e.target.result;
          this.picture.file = file;

          this.clearInput();
        };

        reader.readAsDataURL(file);
      } else {
        this.removeFile(
          this.$filters.ucfirst(
            this.$t("theFileSizeYouAreTryingToUploadIsHigh")
          )
        );
      }
    },
    clearInput() {
      this.fileInput.value = "";
    },
    clearFile() {
      this.picture.imageData = null;
      this.picture.file = null;
    },
    removeFile(message) {
      this.clearInput();

      if (message) {
        this.$swal({
          title: this.$t("error"),
          text: message,
          icon: "error",
        });
      }
    },
    onRemove() {
      this.$swal({
        title: this.$t("pictureWillBeDeleted"),
        text: this.$filters.ucfirst(
          this.$t("areYouSureYouWantToDeleteThePicture")
        ),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("delete"),
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.pictures.splice(this.pictureIndex, 1);
          this.$emit("on-remove", this.pictureIndex);
        }
      });
    },
    setOrderNumber(e) {
      this.picture.orderNumber = e && e.target.value;
    },
    orderAll() {
      for (let i = 0; i < this.pictures.length; i++) {
        const element = this.pictures[i];
        element.orderNumber = i + 1;
      }
      this.setTitle();
      this.setDescription();
    },
    setTitle(e) {
      let result = this.picture.title;

      if (e && e.target) {
        result = e.target.value;
      }
      this.picture.title = result;
    },
    setDescription(e) {
      let result = this.picture.description;

      if (e && e.target) {
        result = e.target.value;
      }
      this.picture.description = result;
    },
    setBtnText(e) {
      let result = this.picture.btn_text;

      if (e && e.target) {
        result = e.target.value;
      }
      this.picture.btn_text = result;
    },
    setBtnTextColor(e) {
      let result = this.picture.btn_text_color;

      if (e && e.target) {
        result = e.target.value;
      }
      this.picture.btn_text_color = result;
    },
    setBtnBgColor(e) {
      let result = this.picture.btn_bg_color;

      if (e && e.target) {
        result = e.target.value;
      }
      this.picture.btn_bg_color = result;
    },
    startDrag(e) {
      e.dataTransfer.dropEffect = "move";
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("pictureIndex", this.pictureIndex);
    },
    onDragOver(e) {
      this.dropzoneHighlight();
    },
    onDragLeave() {
      this.dropzoneHighlight(true);
    },
    onDrop(e) {
      const getIndex = e.dataTransfer.getData("pictureIndex");
      const draggedElement = this.pictures[getIndex];
      const targetElement = this.pictures[this.pictureIndex];

      this.pictures[this.pictureIndex] = draggedElement;
      this.pictures[getIndex] = targetElement;
      this.pictures.sort();
      this.orderAll();

      this.dropzoneHighlight(true);
    },
    closeModal() {
      this.modal = false;
    },
    closeContentModal() {
      this.contentModal = false;
    },
  },
  computed: {
    getHtml() {
      let result = "";
      let mobile =
        this.pictures[this.pictureIndex].imageData &&
        this.pictures[this.pictureIndex].imageData.mobile;
      let desktop =
        this.pictures[this.pictureIndex].imageData &&
        this.pictures[this.pictureIndex].imageData.desktop;
      let src = mobile || desktop;
      if (src) {
        result = `<img src="${src}" class="img-fluid rounded" /> `;
      } else {
        result = '<i class="fas fa-camera text-muted mb-3"></i>';
      }

      return result;
    },

    hideDropzoneDesktop() {
      return (
        this.picture &&
        this.picture.desktop &&
        this.picture.desktop.files &&
        this.picture.desktop.files.length > 0
      );
    },
    hideDropzoneMobile() {
      return (
        this.picture &&
        this.picture.mobile &&
        this.picture.mobile.files &&
        this.picture.mobile.files.length > 0
      );
    },
    picture() {
      return this.pictures[this.pictureIndex];
    },
    fileInput() {
      return this.$refs[this.pictureIndex + "-file"];
    },
    getDisableStatus() {
      if (!this.picture.active) {
        return this.activeCount === this.activeLimit;
      } else {
        return false;
      }
    },
    today() {
      return new Date().toISOString();
    },
    getImage() {
      return `<img src="${this.picture.imageData}" class="img-fluid" />`;
    },
  },
  mounted() {
    this.dropzoneHighlight(true);
    this.setTitle();
    this.setDescription();
  },
};
</script>
<style lang="scss">
.highlight {
  border-color: #2ed573;
  background-color: #2ed5742f;
}
.verte__guide {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}
</style>
